import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import { push as Menu } from 'react-burger-menu'
import Drilldown from './drilldown'

const MobileMenu = ({
  browser,
  menuItems,
  strings,
  arrowIcon,
  bottomHtml,
  searchForm,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('mobile-menu-open')
    } else {
      document.body.classList.remove('mobile-menu-open')
    }
  }, [isMenuOpen])

  let burgerMenuSettings = {
    width: '100%',
    right: true,
    disableAutoFocus: true,
    isOpen: isMenuOpen,
    customBurgerIcon: (
      <div className="menu-toggle">
        <span className={`open`}>
          <span></span>
          <span></span>
          <span></span>
        </span>
        <span className="sr-only">Menu</span>
      </div>
    ),
    // outerContainerId: 'dhsv-outer-container',
    // pageWrapId: 'dhsv-page-wrap',
    onClose: () => setIsMenuOpen(false),
    onStateChange: state => setIsMenuOpen(state.isOpen),
  }

  return menuItems.length && browser.lessThan.md ? (
    <Menu {...burgerMenuSettings}>
      <div className="menu-toggle" onClick={() => setIsMenuOpen(false)}>
        <span className={`menu-toggle__close`}></span>
      </div>
      <div className="mobile_menu__middle">
        <Drilldown items={menuItems} strings={strings} arrowIcon={arrowIcon} />
        <div dangerouslySetInnerHTML={{ __html: searchForm }} />
      </div>
      <div
        className="mobile_menu__bottom"
        dangerouslySetInnerHTML={{ __html: bottomHtml }}
      />
    </Menu>
  ) : (
    ''
  )
}

MobileMenu.propTypes = {
  browser: PropTypes.object,
  menuItems: PropTypes.array,
  bottomHtml: PropTypes.string,
  strings: PropTypes.object,
  arrowIcon: PropTypes.string,
  searchForm: PropTypes.string,
}

export default MobileMenu
