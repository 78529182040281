import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import DrilldownItem from './drilldownItem'

const Drilldown = ({ items, strings, arrowIcon }) => {
  const [activeMenu, setActiveMenu] = useState(0)
  const [menuList, setMenuList] = useState([])
  const [isMounted, setIsMounted] = useState(false)
  const dropdownRef = useRef(null)

  useEffect(() => {
    if (!isMounted) {
      setMenuList(buildMenu(items))
      setIsMounted(!isMounted)
    }
  })

  const buildMenu = (
    items,
    parentItem = false,
    parentMenu = false,
    list = [],
    lvl = 0
  ) => {
    let newMenu = {
      lvl: lvl,
      title: !parentItem ? strings.defaultMenuTitle : parentItem.title,
      name: parentItem ? parentItem.name : 0,
      parent: parentMenu,
      items: [...items],
    }
    list = [...list, newMenu]
    items.map(item => {
      if (item.children.length) {
        list = buildMenu(item.children, item, newMenu.name, list, lvl + 1)
      }
    })
    return list
  }

  return (
    <div className="drilldown" ref={dropdownRef}>
      {menuList.map((menu, i) => (
        <CSSTransition
          key={i}
          in={activeMenu === menu.name}
          timeout={400}
          unmountOnExit
          classNames="menu-list-item"
          className={`menu ${menu.name}`}
        >
          <ul className="menu">
            {menu.parent !== false ? (
              <DrilldownItem
                setActiveMenu={setActiveMenu}
                goToMenu={menu.parent}
                backButton={true}
              >
                <p className="parent_page">
                  <span dangerouslySetInnerHTML={{ __html: arrowIcon }}></span>
                  {menu.title}
                </p>
              </DrilldownItem>
            ) : (
              ''
            )}
            {menu.items.map((item, key) => (
              <DrilldownItem
                key={key}
                setActiveMenu={setActiveMenu}
                url={item.url}
                rightIcon={
                  item.children.length ? (
                    <span
                      dangerouslySetInnerHTML={{ __html: arrowIcon }}
                    ></span>
                  ) : (
                    ''
                  )
                }
                goToMenu={item.children.length ? item.name : false}
              >
                {item.title}
              </DrilldownItem>
            ))}
          </ul>
        </CSSTransition>
      ))}
    </div>
  )
}

Drilldown.propTypes = {
  items: PropTypes.array.isRequired,
  strings: PropTypes.object.isRequired,
  arrowIcon: PropTypes.string,
}

export default Drilldown
